import React, { useState, useEffect } from 'react';
import { Container, Button, Step } from 'semantic-ui-react';
import axios from '../utils/Axios';
import connectPbxVoiceClient from '../App';
import socket from '../utils/SocketIo';
import NavBar from './NavBar';
import audioFile from '../sounds/wait.mp3';
import Timer from '../components/timer.js';
import { getURI } from '../utils/gina';


function Out({ tel, idAgente, compainNumber, caseCompain, pbxToken, device,setHangUpAgent,endPointUser}) {
  const [callSid, setCallSid] = useState(null);
  const [isCalling, setIsCalling] = useState(false);
  const [currentTel, setCurrentTel] = useState(tel);
  const audio = new Audio(audioFile);
  const [isMuted, setIsMuted] = useState(false); // Estado para el micrófono inicialmente en silencio
  const [connected , setConnected] = useState(false);
  const [timer, setTimer] = useState(false);
 //console.log(`Number company  ` , compainNumber , `Number a marcar` ,tel )
  //console.log(`llegando el valor de OUTTTTT `, tel, idAgente, compainNumber, device,endPointUser,sipValue);
  async function postJSONnew(data) {
    try {
      const response = await fetch(
        endPointUser,
        {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  useEffect(() => {
    //comp timer
    socket.on('setTimer', (value,socketId) => {
     
     if (socket.id === socketId && value ) {
       console.log(`Timer Active Comp`);
       setTimer(value)
     } 
   });
 
   socket.on('connected', (connectedCall, socketId) => {
     if (connectedCall && socket.id=== socketId) {
       console.log(`Call Connected Comp`);
      audio.pause();
      
     } 
   });  
   }, []);

/*
  useEffect(() => {
    socket.on('connected', (connectedCall) => {
      console.log(`EVENTO EMITIDOO`);
      if (connectedCall) {
        setConnected(connectedCall);
        audio.pause();
        
      } else {
        console.log('No se pudo establecer la llamada ', connectedCall);
      }
    });
    
    socket.on(`hangUpCall` , (value)=>{
        if(value){
          console.log(`SILENCIANDOOOOOOOO`);
          audio.pause();
        }
    });
    
  }, []);
  
*/
   


 
  const handleCallClick = () => {
    if (currentTel && idAgente) {   
      //requestMicrophonePermission(); 
      console.log('Dispositivo listo');
      audio.play();
      axios
        .post(`/voice`, {
          To: currentTel,
          Agente: idAgente,
          From: compainNumber,
        })   //callsid
        .then((response) => {
          //console.log('EL sid de la llamada es:', response.data);
          setCallSid(response.data); 
          socket.emit(`call-sid-out`,response.data,socket.id);
          const dataCall = {
            EVENTO: 'update-callsid',
            IDAGENTE: idAgente,
            CallSid: response.data,
            COMPANYNUMBER: compainNumber,
            CLIENTNUMBER: currentTel,
            SKILL: '',
            IDCASO: '',
          };
          setTimeout(()=>{
            window.parent.postMessage(dataCall, '*');
           }, 1000);
          console.log('Datos para Gina de update-callsid: ', dataCall);
          // comentado para pruebas
           postJSONnew(dataCall);
        })
        .catch((error) => {
          // Manejar los errores aquí
          console.error('Error al redireccionar la llamada:', error);
        });
    } else {
      console.log('Faltan datos necesarios para manejar la llamada.');
    }
    setIsCalling(true);
  };

  const handleHangupClick = () => {
    if (isCalling && callSid) {
       audio.pause();
      axios
        .post(`/hang-up-call`, {
          CallSid: callSid,
          Agente: idAgente,
        })
        .then((response) => {
          // Manejar la respuesta del servicio
          setHangUpAgent(false);
          console.log('La llamada ha sido colgada:', response.data);
         
          //window.location.reload();
        })
        .catch((error) => {
          // Manejar los errores
          console.error('Error al colgar la llamada:', error);
        });
      setIsCalling(false); // Cambiar el estado a false para indicar que la llamada ha sido colgada.
    }
  };

  useEffect(() => {
    setCurrentTel(currentTel);
  }, [currentTel]);

  const handleMuteClick = () => {
    const activeConnection = device.activeConnection();
    if (activeConnection) {
      activeConnection.mute(true); // Mutea el micrófono
      setIsMuted(true);
    } else {
      console.log('No hay una llamada activa.');
    }
  };
  
  const handleUnmuteClick = () => {
    const activeConnection = device.activeConnection();
    if (activeConnection) {
      activeConnection.mute(false); // Desmutear el micrófono
      setIsMuted(false);
    } else {
      console.log('No hay una llamada activa.');
    }
  };

  return (
    <div>
      <NavBar />
      <Container>
        <Step.Group fluid>
          <Step
            icon='phone'
            title='Realizar llamada'
            description={'Número a marcar: ' + currentTel}
            active={!isCalling}
            disabled={isCalling}
            onClick={handleCallClick}
          />

          <Step
            icon='headphones'
            title='Colgar'
            description='Colgar la llamada'
            onClick={handleHangupClick} // Agregar la función de clic para colgar la llamada
            disabled={!isCalling} // Deshabilitar si no se está realizando una llamada
          />
           <Step
          icon={isMuted ? 'microphone slash' : 'microphone'}
          title={isMuted ? 'Activar' : 'Silenciar'}
          disabled={!isCalling} // Deshabilitar si no se está realizando una llamada
          onClick={isMuted ? handleUnmuteClick : handleMuteClick}
          />
            <Step
            icon='clock'
            title='Tiempo'
          />
           {timer && <Timer />} 
        </Step.Group>
      </Container>
    </div>
  );
};

export default Out;

let IDCASO;
let URICLIENT;
const setURIClient = (uri) =>{
  URICLIENT = uri;
}


const processEndPointUser = (endPointUser) => {
  setURIClient(endPointUser);

};
export { processEndPointUser };

async function postJSONend(data) {
    try {
      const response = await fetch(
        URICLIENT,
        {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
  
      const result = await response.json();
      console.log('Success:', result);
      return result;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function postJSONnew(data) {
    try {
      const response = await fetch(
        URICLIENT,
        {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
  
      const result = await response.json();
      console.log('Success:', result.IDCASO);
      IDCASO = result.IDCASO;
      console.log('Global IDCASO:', IDCASO);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  //outbounds
  export  function haciaGinaEndCallOutound(idAgente,callSid,To,From,queue,idCaso){
    
     const dataCall = {
      EVENTO: 'call-end',
      IDAGENTE: idAgente,
      CallSid: callSid,
      COMPANYNUMBER: From,
      CLIENTNUMBER: To,
      SKILL: 'ventas',
      IDCASO: idCaso,
    };

    console.log(`CHACIA GINA end-Call-Outbound`, dataCall);
    setTimeout(()=>{
      window.parent.postMessage(dataCall, '*');
     }, 1000);
    postJSONend(dataCall);
   
   };
   //OUTS 
   export  function haciaGinaEndCallOut(idAgente,callSid,To,From,queue,idCaso){
   
    const dataCall = {
     EVENTO: 'call-end',
     IDAGENTE: idAgente,
     CallSid: callSid,
     COMPANYNUMBER: From,
     CLIENTNUMBER: To,
     SKILL: 'ventas',
     IDCASO: idCaso,
   };

   console.log(`CHACIA GINA end-Call-Out`, dataCall);
   setTimeout(()=>{
     window.parent.postMessage(dataCall, '*');
    }, 1000);
   postJSONend(dataCall);
  
  };


  export  function haciaGinaNewCall(idAgente,callSid,To,From,queue){
    const dataCall = {
        EVENTO: 'call-new',
        IDAGENTE: idAgente,
        CallSid: callSid,
        COMPANYNUMBER: To,
        CLIENTNUMBER: From,
        SKILL: queue,
        IDCASO: '',
      };

      console.log(`Hacia gina call-new  GINA`, dataCall);
      console.log(`VALOR DE URI EN GINA` , URICLIENT);
      postJSONnew(dataCall);
      window.postMessage(dataCall , '*');
      // Comentado para pruebas
      

   }

   //vaaa comentadoooo
   export  function haciaGinaEndCall(idAgente,callSid,To,From,queue){
    const dataCall = {
      EVENTO: 'call-end',
      IDAGENTE: idAgente,
      CallSid: callSid,
      COMPANYNUMBER: To,
      CLIENTNUMBER: From,
      SKILL: queue,
      IDCASO: IDCASO,
    };

      console.log(`Hacia gina end-call  GINA`, dataCall);
      window.postMessage(dataCall , '*');
      // Comentado para pruebas
      //postJSONend(dataCall);

   }

   export  function haciaGinaEndCallTransfer(idAgente,callSid,To,From,queue,idCaso){
    const dataCall = {
      EVENTO: 'call-end',
      IDAGENTE: idAgente,
      CallSid: callSid,
      COMPANYNUMBER: To,
      CLIENTNUMBER: From,
      SKILL: queue,
      IDCASO: idCaso ,
    };

    console.log(`CHACIA GINA end-Call- Transferencia`, dataCall);
    postJSONend(dataCall);
  
   };
  

   export  function haciaGinaNewCallTransfer(idAgente,callSid,To,From,queue,idCaso){
    const dataCall = {
        EVENTO: 'call-new',
        IDAGENTE: idAgente,
        CallSid: callSid,
        COMPANYNUMBER: To,
        CLIENTNUMBER: From,
        SKILL: queue,
        IDCASO: idCaso,
    };
      console.log(`Hacia gina call-new TRANSFERRRR GINA`, dataCall)
      postJSONnew(dataCall);
      window.postMessage(dataCall , '*');
     

   };

import React from 'react';
import { Menu, Container, Image } from 'semantic-ui-react';

function NavBar() {
  return (
    <Menu>
      <Container text>
        <Menu.Item>
          <i className='phone icon'></i>
        </Menu.Item>
        <Menu.Item header>Gina Wex</Menu.Item>
        <Menu.Item position='right'>
          <Image
            src='https://react.semantic-ui.com/images/avatar/large/chris.jpg'
            avatar
          />
        </Menu.Item>
        <Menu.Item>v.0301.1821</Menu.Item>
      </Container>
    </Menu>
  );
}

export default NavBar;

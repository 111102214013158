import React, { useState } from 'react';
import NavBar from './NavBar';
import { Container, Step } from 'semantic-ui-react';
import socket from '../utils/SocketIo';
import { handleHangupClick } from '../utils/hangUpCall.js';

function CallCenter({ callSid, from, callStatus, idAgente,device }) {
  const [callHungUp, setCallHungUp] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [isMuted, setIsMuted] = useState(false); 
  console.log(`ELL CALLLSIDD en CALLCENTER ` , callSid)
  function answerCall(sid) {
    socket.emit('answer-call', { sid }, idAgente);
    setIsCalling(true);
  }

  const handleHangup = () => {
    if (!callHungUp) {
      handleHangupClick(callSid, idAgente);
      setCallHungUp(true);
    }
  };

  const handleMuteClick = () => {
    const activeConnection = device.activeConnection();
    if (activeConnection) {
      activeConnection.mute(true); // Mutea el micrófono
      setIsMuted(true);
    } else {
      console.log('No hay una llamada activa.');
    }
  };
  
  const handleUnmuteClick = () => {
    const activeConnection = device.activeConnection();
    if (activeConnection) {
      activeConnection.mute(false); // Desmutear el micrófono
      setIsMuted(false);
    } else {
      console.log('No hay una llamada activa.');
    }
  };

  return (
    <div>
      <NavBar />
      <Container>
        <Step.Group fluid>
          <Step
            icon='phone'
            title='Numero Cliente'
            description={from}
            active={callStatus === 'ringing'}
            completed={callStatus !== 'ringing'}
          />
          <Step
            icon='cogs'
            title='Contestar'
            description='Cliente en cola'
            active={callStatus === 'enqueue'}
            disabled={callStatus === 'ringing'}
            onClick={() => answerCall(callSid)}
          />
          <Step
            icon='headphones'
            title='Colgar'
            disabled ={!isCalling && callSid}
            description='Colgar la llamada'
            onClick={handleHangup}
          />
          <Step
          icon={isMuted ? 'microphone slash' : 'microphone'}
          title={isMuted ? 'Activar' : 'Silenciar'}
          disabled={!isCalling && callSid} // Deshabilitar si no se está realizando una llamada
          onClick={isMuted ? handleUnmuteClick : handleMuteClick}
          />
        </Step.Group>
      </Container>
    </div>
  );
}

export default CallCenter;

